import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    const showAlert = sessionStorage.getItem("newAppAlertBarShow")

    if (showAlert === "false") {
      this.element.classList.add("d-none")
    }
  }

  close() {
    sessionStorage.setItem("newAppAlertBarShow", "false");
    this.element.classList.add("d-none")
  }
}
