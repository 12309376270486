import { Controller } from "stimulus"
import 'bootstrap'

export default class extends Controller {

  static targets = [ "name", "email", "mobile", "notes", "sendEmailBtn", "form" ]
  static values = { recipientEmail: String, alert: String, emailUrl: String }

  sendEmail(event) {
    event.preventDefault();

    this.#sendEmailToLead()

    const senderName = this.nameTarget.value
    const senderPhone = this.mobileTarget.value
    const senderEmail = this.emailTarget.value
    const senderNotes = this.notesTarget.value
    const recipientEmail = this.recipientEmailValue
    const sendEmailBtn = this.sendEmailBtnTarget

    const bodyEmail = `Hello,%0D%0A%0D%0AThese are my contact details:%0D%0AName: ${senderName}%0D%0AMobile: ${senderPhone}%0D%0AEmail: ${senderEmail}%0D%0ANotes: ${senderNotes}%0D%0A%0D%0AThank you!`
    
    sendEmailBtn.href = `mailto:${recipientEmail}?subject=${senderName} | New contact - WalliM Business Card&body=${bodyEmail}`
    sendEmailBtn.click();
  }

  createConnection(event) {
    event.preventDefault()
    const form = event.target
    
    this.#sendEmailToLead()

    fetch(form.action, {
      method: 'POST',
      headers: {
        'Accept': 'text/plain',
      },
      body: new FormData(form)
    })
      .then(response => response.text())
      .then((data) => {
        form.outerHTML = data

        if(document.querySelector(".invalid-feedback") == null) {
          const modalBackdrop = document.querySelector(".modal-backdrop")
          modalBackdrop.remove()

          this.element.classList.remove("show")
          this.element.style.display = "none"
          this.element.removeAttribute("aria-modal")
          this.element.removeAttribute("role")
          this.element.setAttribute("aria-hidden", "true")

          document.body.style.overflow = null
          document.body.style.paddingRight = null
          document.body.classList.remove("modal-open")

          document.body.insertAdjacentHTML("beforeend", `<div class="notice alert alert-info alert-dismissible fade show m-1 shadow rounded-3-5" role="alert">
            <div class="d-flex align-items-center justify-content-start gap-3">
              <i class="fa-solid fa-circle-check fs-1"></i>
              <p class="m-0">${this.alertValue}</p>
            </div>
            <button type="button" class="wallim-btn-close green-color" data-bs-dismiss="alert" aria-label="Close">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>`)

          this.nameTarget.value = ""
          this.nameTarget.style.backgroundImage = "none"
          this.nameTarget.classList.remove("is-valid")

          this.emailTarget.value = ""
          this.emailTarget.style.backgroundImage = "none"
          this.emailTarget.classList.remove("is-valid")

          this.mobileTarget.value = ""
          this.mobileTarget.style.backgroundImage = "none"
          this.mobileTarget.classList.remove("is-valid")

          this.notesTarget.value = ""
          this.notesTarget.style.backgroundImage = "none"
          this.notesTarget.classList.remove("is-valid")
        }
      })
  }

  #sendEmailToLead() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(this.emailUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Accept': 'text/plain',
      },
      body: new FormData(this.formTarget)
    })
  }
}
