// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require Chart.bundle

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

// import of bootstrap -> Telo -> in order for the standard js to work - https://dev.to/songta17/rails-6-with-bootstrap-5-5c08
import 'bootstrap';

//import plain JS
import  { test } from '../plain/test.js';
test();

// import chartkick:
// import "chartkick/chart.js";

//import pwa_default_prompt
import  { pwa_default_prompt } from '../plain/pwa_default_prompt.js';
pwa_default_prompt();



if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
        .then((reg) => {
          console.log('Service worker registered.', reg);
        });
  });
}

require("trix")
require("@rails/actiontext")